import React, { useState } from "react";
import {
	Box,
	Typography,
	TextField,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
	Grid,
	Button,
	Tooltip,
	Container,
	Card,
	CardContent,
	Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import { School } from "@mui/icons-material";
import { calculatePercentageFromCGPA, marksIntialState } from "./utils";

// Styled Box for input sections
const StyledSection = styled(Box)(({ theme }) => ({
	border: "1px solid rgba(0, 0, 0, 0.3)",
	padding: theme.spacing(3),
	borderRadius: "10px",
	marginBottom: theme.spacing(4),
	backgroundColor: "#f4f6f8",
	boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
}));

const StyledContainer = styled(Container)(({ theme }) => ({
	backgroundColor: "#e9f5ee",
	padding: theme.spacing(3),
	borderRadius: "15px",
	boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
	marginTop: theme.spacing(3),
}));

const ThreeExamsSet = () => {
	const [matricMarks, setMatricMarks] = useState(marksIntialState);
	const [intermediateMarks, setIntermediateMarks] = useState(marksIntialState);
	const [graduationSystem, setGraduationSystem] = useState("semester");
	const [graduationMarks, setGraduationMarks] = useState(marksIntialState);
	const [graduationCGPA, setGraduationCGPA] = useState({
		total: 4,
		obtained: "",
	});
	const [graduationCGPASystem, setGraduationCGPASystem] = useState("4");
	const [results, setResults] = useState({
		matricPercentage: "",
		intermediatePercentage: "",
		graduationPercentage: "",
		totalPercentage: "",
	});
	const [showResults, setShowResults] = useState(false);

	const handleGraduationSystemChange = (event) => {
		setGraduationSystem(event.target.value);
		setGraduationMarks({ total: 1000, obtained: "" });
		setGraduationCGPA({ total: 4, obtained: "" });
		setGraduationCGPASystem("4");
	};

	const handleCalculate = () => {
		const matricPercentage =
			(parseFloat(matricMarks.obtained) / parseFloat(matricMarks.total)) *
				100 || 0;
		const intermediatePercentage =
			(parseFloat(intermediateMarks.obtained) /
				parseFloat(intermediateMarks.total)) *
				100 || 0;

		let graduationPercentage = 0;
		if (graduationSystem === "semester") {
			graduationPercentage =
				calculatePercentageFromCGPA(parseFloat(graduationCGPA.obtained)) || 0;
		} else {
			graduationPercentage =
				(parseFloat(graduationMarks.obtained) /
					parseFloat(graduationMarks.total)) *
					100 || 0;
		}

		const matric = (matricPercentage * 5) / 100;
		const intermediate = (intermediatePercentage * 7) / 100;
		const graduation = (graduationPercentage * 28) / 100;
		const totalPercentage = matric + intermediate + graduation;

		setResults({
			matricPercentage: matricPercentage.toFixed(2),
			intermediatePercentage: intermediatePercentage.toFixed(2),
			graduationPercentage: graduationPercentage,
			totalPercentage: totalPercentage.toFixed(2),
		});
		setShowResults(true);
	};

	const isCalculateDisabled = () => {
		const matricValid =
			matricMarks.obtained &&
			matricMarks.total &&
			!isNaN(parseFloat(matricMarks.obtained)) &&
			!isNaN(parseFloat(matricMarks.total));
		const intermediateValid =
			intermediateMarks.obtained &&
			intermediateMarks.total &&
			!isNaN(parseFloat(intermediateMarks.obtained)) &&
			!isNaN(parseFloat(intermediateMarks.total));
		const graduationValid =
			graduationSystem === "semester"
				? graduationCGPA.obtained &&
				  graduationCGPA.total &&
				  !isNaN(parseFloat(graduationCGPA.obtained))
				: graduationMarks.obtained &&
				  graduationMarks.total &&
				  !isNaN(parseFloat(graduationMarks.obtained)) &&
				  !isNaN(parseFloat(graduationMarks.total));

		return !(matricValid && intermediateValid && graduationValid);
	};

	return (
		<StyledContainer maxWidth="sm">
			<Box p={2}>
				<Typography
					variant="h4"
					gutterBottom
					sx={{
						fontSize: { xs: "1.5rem", sm: "2rem" },
						textAlign: "center",
						fontFamily: "'Poppins', Arial, sans-serif",
						fontWeight: 500,
						color: "#2f8831",
					}}
				>
					Three Exams Set
				</Typography>
				<Typography
					variant="h6"
					gutterBottom
					sx={{
						fontSize: { xs: "1rem", sm: "1.25rem" },
						textAlign: "center",
						fontFamily: "'Poppins', Arial, sans-serif",
						fontWeight: 400,
						color: "#555",
					}}
				>
					Enter Your Marks
				</Typography>

				<StyledSection>
					<Typography variant="h6" mb={2} color="primary">
						Matriculation Marks
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Total Marks"
								value={matricMarks.total}
								variant="outlined"
								onChange={(e) =>
									setMatricMarks({ ...matricMarks, total: e.target.value })
								}
								fullWidth
								type="number"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Obtained Marks"
								value={matricMarks.obtained}
								onChange={(e) =>
									setMatricMarks({ ...matricMarks, obtained: e.target.value })
								}
								fullWidth
								type="number"
							/>
						</Grid>
					</Grid>
				</StyledSection>

				<StyledSection>
					<Typography variant="h6" mb={2} color="primary">
						Intermediate Marks
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Total Marks"
								value={intermediateMarks.total}
								variant="outlined"
								onChange={(e) =>
									setIntermediateMarks({
										...intermediateMarks,
										total: e.target.value,
									})
								}
								fullWidth
								type="number"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Obtained Marks"
								value={intermediateMarks.obtained}
								onChange={(e) =>
									setIntermediateMarks({
										...intermediateMarks,
										obtained: e.target.value,
									})
								}
								fullWidth
								type="number"
							/>
						</Grid>
					</Grid>
				</StyledSection>

				<StyledSection>
					<Typography variant="h6" mb={2} color="primary">
						Graduation Marks
					</Typography>
					<FormControl component="fieldset" fullWidth>
						<FormLabel component="legend">Select Graduation System</FormLabel>
						<RadioGroup
							row
							value={graduationSystem}
							onChange={handleGraduationSystemChange}
						>
							<FormControlLabel
								value="semester"
								control={<Radio />}
								label="Semester System (CGPA)"
							/>
							<FormControlLabel
								value="annual"
								control={<Radio />}
								label="Annual System Marks"
							/>
						</RadioGroup>
					</FormControl>
					{graduationSystem === "semester" ? (
						<Box mt={2}>
							<FormControl component="fieldset" fullWidth>
								<FormLabel component="legend">Select Total CGPA</FormLabel>
								<RadioGroup
									row
									value={graduationCGPASystem}
									onChange={(e) => setGraduationCGPASystem(e.target.value)}
								>
									<FormControlLabel value="4" control={<Radio />} label="4" />
									<FormControlLabel value="5" control={<Radio />} label="5" />
								</RadioGroup>
							</FormControl>
							<TextField
								label="Obtained CGPA"
								value={graduationCGPA.obtained}
								onChange={(e) =>
									setGraduationCGPA({
										...graduationCGPA,
										obtained: e.target.value,
									})
								}
								fullWidth
								type="number"
								sx={{ mt: 2 }}
							/>
						</Box>
					) : (
						<Grid container spacing={3} mt={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									label="Total Marks"
									value={graduationMarks.total}
									onChange={(e) =>
										setGraduationMarks({
											...graduationMarks,
											total: e.target.value,
										})
									}
									fullWidth
									type="number"
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									label="Obtained Marks"
									value={graduationMarks.obtained}
									onChange={(e) =>
										setGraduationMarks({
											...graduationMarks,
											obtained: e.target.value,
										})
									}
									fullWidth
									type="number"
								/>
							</Grid>
						</Grid>
					)}
				</StyledSection>

				<Tooltip title="Please Fill Academic Marks to Calculate">
					<Button
						variant="contained"
						color="primary"
						onClick={handleCalculate}
						disabled={isCalculateDisabled()}
						fullWidth
						sx={{
							mt: 2,
							padding: (theme) => theme.spacing(1.5),
							backgroundColor: "#2f8831",
							"&:hover": {
								backgroundColor: "#276e28",
							},
						}}
					>
						Calculate
					</Button>
				</Tooltip>

				{showResults && (
					<Box mt={4} display="flex" justifyContent="center">
						<Card
							variant="outlined"
							sx={{ maxWidth: 500, width: "100%", boxShadow: 4 }}
						>
							<CardContent>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="center"
									mb={2}
								>
									<School fontSize="large" color="primary" />
									<Typography
										variant="h5"
										align="center"
										ml={1}
										color="primary"
									>
										Calculated Results
									</Typography>
								</Box>

								<Divider />

								<Box mt={2} mb={2}>
									<Typography variant="body1" color="textSecondary">
										Matric Percentage:{" "}
										<strong>{results.matricPercentage}%</strong>
									</Typography>
									<Typography variant="body1" color="textSecondary">
										Intermediate Percentage:{" "}
										<strong>{results.intermediatePercentage}%</strong>
									</Typography>
									<Typography variant="body1" color="textSecondary">
										Graduation Percentage:{" "}
										<strong>{results.graduationPercentage}%</strong>
									</Typography>
								</Box>

								<Divider />

								<Box mt={3}>
									<Typography
										variant="h4"
										align="center"
										color="primary"
										fontWeight="bold"
									>
										Total Academic Marks:
									</Typography>
									<Typography
										variant="h4"
										align="center"
										color="primary"
									>{`${results.totalPercentage} / 40`}</Typography>
								</Box>
							</CardContent>
						</Card>
					</Box>
				)}
			</Box>
		</StyledContainer>
	);
};

export default ThreeExamsSet;
