import { Typography } from "@mui/material";

const calculatePercentageFromCGPA = (cgpa, totalCGPA = 4) => {
	if (totalCGPA === 4) {
		if (cgpa >= 3.63) {
			return ((cgpa - 0.3) / 0.037).toFixed(2);
		} else if (cgpa >= 3.25) {
			return ((cgpa - 0.29) / 0.037).toFixed(2);
		} else if (cgpa >= 2.88) {
			return ((cgpa - 0.36) / 0.036).toFixed(2);
		} else if (cgpa >= 2.5) {
			return ((cgpa - 0.28) / 0.037).toFixed(2);
		} else if (cgpa >= 1.8) {
			return ((cgpa + 1.65) / 0.069).toFixed(2);
		} else if (cgpa >= 1) {
			return ((cgpa + 2.16) / 0.079).toFixed(2);
		} else if (cgpa > 0) {
			return (cgpa / 0.0248).toFixed(2);
		} else {
			return 0;
		}
	} else if (totalCGPA === 5) {
		if (cgpa >= 4.63) {
			return ((cgpa - 1.3) / 0.037).toFixed(2);
		} else if (cgpa >= 4.25) {
			return ((cgpa - 1.29) / 0.037).toFixed(2);
		} else if (cgpa >= 3.88) {
			return ((cgpa - 1.36) / 0.036).toFixed(2);
		} else if (cgpa >= 3.5) {
			return ((cgpa - 1.28) / 0.037).toFixed(2);
		} else if (cgpa >= 2.8) {
			return ((cgpa + 0.65) / 0.069).toFixed(2);
		} else if (cgpa >= 2) {
			return ((cgpa + 1.16) / 0.079).toFixed(2);
		} else if (cgpa > 0) {
			return ((cgpa - 1) / 0.0248).toFixed(2);
		} else {
			return 0;
		}
	}
};

const marksIntialState = { total: 1100, obtained: "" };

const DegreeTitle = ({ title }) => (
	<Typography variant="h6">{title} Marks</Typography>
);

export { calculatePercentageFromCGPA, marksIntialState, DegreeTitle };
