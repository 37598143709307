import React, { useState } from "react";
import {
	Box,
	Typography,
	TextField,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
	Grid,
	Button,
	Tooltip,
	Container,
	Card,
	CardContent,
	Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import { School } from "@mui/icons-material";
import {
	calculatePercentageFromCGPA,
	marksIntialState,
	DegreeTitle,
} from "./utils";

// Styled Box for input sections
const StyledSection = styled(Box)(({ theme }) => ({
	border: "1px solid rgba(0, 0, 0, 0.3)",
	padding: theme.spacing(3),
	borderRadius: "10px",
	marginBottom: theme.spacing(4),
	backgroundColor: "#f4f6f8",
	boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
}));

const StyledContainer = styled(Container)(({ theme }) => ({
	backgroundColor: "#e9f5ee",
	padding: theme.spacing(3),
	borderRadius: "15px",
	boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
	marginTop: theme.spacing(3),
}));

const FourExamsSet = () => {
	// Inter and Matric
	const [matricMarks, setMatricMarks] = useState(marksIntialState);
	const [intermediateMarks, setIntermediateMarks] = useState(marksIntialState);

	// ----------- BA --------------
	const [baSystem, setBaSystem] = useState("annual");
	const [baTotalMarks, setBaTotalMarks] = useState(800);
	const [baGainedMarks, setBaGainedMarks] = useState("");
	const [baTotalCgpa, setBaTotalCgpa] = useState(4);
	const [baGainedCgpa, setBaGainedCgpa] = useState("");

	//---------------- MA ---------------
	const [maSystem, setMASystem] = useState("semester");
	const [maTotalMarks, setMaTotalMarks] = useState(1400);
	const [maGainedMarks, setMaGainedMarks] = useState("");
	const [maMaTotalCGPA, setMaTotalCGPA] = useState(4);
	const [maGainedCgpa, setMaGainedCgpa] = useState("");

	// Results
	const [results, setResults] = useState({
		matricPercentage: "",
		intermediatePercentage: "",
		baPercentage: "",
		maPercentage: "",
		totalPercentage: "",
	});

	const [showResults, setShowResults] = useState(false);

	const calcualtResults = () => {
		const matricPercentage = (matricMarks.obtained / matricMarks.total) * 100;
		const intermediatePercentage =
			(intermediateMarks.obtained / intermediateMarks.total) * 100;
		const baPercentage =
			baSystem === "annual"
				? (baGainedMarks / baTotalMarks) * 100
				: calculatePercentageFromCGPA(baGainedCgpa);
		const maPercentage =
			maSystem === "annual"
				? (maGainedMarks / maTotalMarks) * 100
				: calculatePercentageFromCGPA(maGainedCgpa);

		setResults({
			matricPercentage: matricPercentage.toFixed(2),
			intermediatePercentage: intermediatePercentage.toFixed(2),
			baPercentage: baPercentage.toFixed(2),
			maPercentage: maPercentage,
			totalPercentage: (
				(matricPercentage * 5) / 100 +
				(intermediatePercentage * 7) / 100 +
				(baPercentage * 11) / 100 +
				(maPercentage * 17) / 100
			).toFixed(2),
		});
	};
	const handleSystemChange = (event, exam) =>
		exam === "MA"
			? setMASystem(event.target.value)
			: setBaSystem(event.target.value);

	const isCalculateButtonDisabled = () => {
		if (
			!matricMarks.total ||
			!matricMarks.obtained ||
			!intermediateMarks.total ||
			!intermediateMarks.obtained ||
			(baSystem === "annual" && (!baTotalMarks || !baGainedMarks)) ||
			(baSystem === "semester" && !baGainedCgpa) ||
			(maSystem === "annual" && (!maTotalMarks || !maGainedMarks)) ||
			(maSystem === "semester" && !maGainedCgpa)
		) {
			return true;
		}
		return false;
	};

	return (
		<StyledContainer maxWidth="sm">
			<Box p={2}>
				<Typography
					variant="h4"
					gutterBottom
					sx={{
						fontSize: {
							xs: "1.5rem",
							sm: "2rem",
							fontFamily: "'Poppins', Arial, sans-serif",
							fontWeight: 500,
							color: "#2f8831",
						},
						textAlign: "center",
					}}
				>
					Four Exams Set
				</Typography>

				<Typography
					variant="h6"
					gutterBottom
					sx={{
						fontSize: {
							xs: "1rem",
							sm: "1.25rem",
							fontFamily: "'Poppins', Arial, sans-serif",
							fontWeight: 400,
							color: "#555",
						},
						textAlign: "center",
					}}
				>
					Enter Your Marks
				</Typography>

				<StyledSection>
					<Typography variant="h6" mb={2} color="primary">
						Matriculation Marks
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Total Marks"
								value={matricMarks.total}
								onChange={(e) =>
									setMatricMarks({ ...matricMarks, total: e.target.value })
								}
								type="number"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Obtained Marks"
								value={matricMarks.obtained}
								onChange={(e) =>
									setMatricMarks({ ...matricMarks, obtained: e.target.value })
								}
								type="number"
								fullWidth
							/>
						</Grid>
					</Grid>
				</StyledSection>

				<StyledSection>
					<Typography variant="h6" mb={2} color="primary">
						Intermediate Marks
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Total Marks"
								value={intermediateMarks.total}
								onChange={(e) =>
									setIntermediateMarks({
										...intermediateMarks,
										total: e.target.value,
									})
								}
								type="number"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Obtained Marks"
								value={intermediateMarks.obtained}
								onChange={(e) =>
									setIntermediateMarks({
										...intermediateMarks,
										obtained: e.target.value,
									})
								}
								type="number"
								fullWidth
							/>
						</Grid>
					</Grid>
				</StyledSection>

				<StyledSection>
					<DegreeTitle title="BA" />
					<FormControl component="fieldset" fullWidth>
						<FormLabel component="legend">Select Grading System</FormLabel>

						<RadioGroup
							row
							value={baSystem}
							onChange={(e) => handleSystemChange(e, "BA")}
						>
							<FormControlLabel
								value="semester"
								control={<Radio />}
								label="Semester"
							/>
							<FormControlLabel
								value="annual"
								control={<Radio />}
								label="Annual"
							/>
						</RadioGroup>
					</FormControl>
					{baSystem === "semester" ? (
						<>
							<FormControl component="fieldset" fullWidth>
								<FormLabel component="legend">Select Total CGPA</FormLabel>
								<RadioGroup
									row
									value={baTotalCgpa}
									onChange={(e) => setBaTotalCgpa(e.target.value)}
								>
									<FormControlLabel value={4} control={<Radio />} label="4" />
									<FormControlLabel value={5} control={<Radio />} label="5" />
								</RadioGroup>
							</FormControl>
							<TextField
								label="Obtained CGPA in BA"
								value={baGainedCgpa}
								onChange={(e) => setBaGainedCgpa(e.target.value)}
								type="number"
								fullWidth
							/>
						</>
					) : (
						<Grid container spacing={3} mt={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									label="Total Marks in BA"
									value={baTotalMarks}
									onChange={(e) => setBaTotalMarks(e.target.value)}
									type="number"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									label="Obtained Marks in BA"
									value={baGainedMarks}
									onChange={(e) => setBaGainedMarks(e.target.value)}
									type="number"
									fullWidth
								/>
							</Grid>
						</Grid>
					)}
				</StyledSection>

				<StyledSection>
					<DegreeTitle title="MA" />
					<FormControl component="fieldset" fullWidth>
						<FormLabel component="legend">Select Grading System</FormLabel>
						<RadioGroup
							row
							value={maSystem}
							onChange={(e) => handleSystemChange(e, "MA")}
						>
							<FormControlLabel
								value="semester"
								control={<Radio />}
								label="Semester"
							/>
							<FormControlLabel
								value="annual"
								control={<Radio />}
								label="Annual"
							/>
						</RadioGroup>
					</FormControl>
					{maSystem === "semester" ? (
						<>
							<FormControl component="fieldset" fullWidth>
								<FormLabel component="legend">Select Total CGPA</FormLabel>
								<RadioGroup
									row
									value={maMaTotalCGPA}
									onChange={(e) => setMaTotalCGPA(e.target.value)}
								>
									<FormControlLabel value={4} control={<Radio />} label="4" />
									<FormControlLabel value={5} control={<Radio />} label="5" />
								</RadioGroup>
							</FormControl>
							<TextField
								label="Gained CGPA in MA"
								value={maGainedCgpa}
								onChange={(e) => setMaGainedCgpa(e.target.value)}
								type="number"
								fullWidth
							/>
						</>
					) : (
						<Grid container spacing={3} mt={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									label="Total Marks"
									value={maTotalMarks}
									onChange={(e) => setMaTotalMarks(e.target.value)}
									type="number"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									label="Obtained Marks"
									value={maGainedMarks}
									onChange={(e) => setMaGainedMarks(e.target.value)}
									type="number"
									fullWidth
								/>
							</Grid>
						</Grid>
					)}
				</StyledSection>

				<Tooltip title="Please Fill Academic Marks to Calculate">
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							setShowResults(true);
							calcualtResults();
						}}
						disabled={isCalculateButtonDisabled()}
						fullWidth
						sx={{
							mt: 2,
							padding: (theme) => theme.spacing(1.5),
							backgroundColor: "#2f8831",
							"&:hover": {
								backgroundColor: "#276e28",
							},
						}}
					>
						Calculate
					</Button>
				</Tooltip>

				{showResults && (
					<Box mt={4} display="flex" justifyContent="center">
						<Card
							variant="outlined"
							sx={{ maxWidth: 500, width: "100%", boxShadow: 4 }}
						>
							<CardContent>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="center"
									mb={2}
								>
									<School fontSize="large" color="primary" />
									<Typography
										variant="h5"
										align="center"
										ml={1}
										color="primary"
									>
										Calculated Results
									</Typography>
								</Box>

								<Divider />

								<Box mt={2} mb={2}>
									<Typography variant="body1" color="textSecondary">
										Matric Percentage:{" "}
										<strong>{results.matricPercentage}%</strong>
									</Typography>
									<Typography variant="body1" color="textSecondary">
										Intermediate Percentage:{" "}
										<strong>{results.intermediatePercentage}%</strong>
									</Typography>
									<Typography variant="body1" color="textSecondary">
										BA Percentage: <strong>{results.baPercentage}%</strong>
									</Typography>
									<Typography variant="body1" color="textSecondary">
										MA Percentage: <strong>{results.maPercentage}%</strong>
									</Typography>
								</Box>

								<Divider />

								<Box mt={3}>
									<Typography
										variant="h4"
										align="center"
										color="primary"
										fontWeight="bold"
									>
										Total Academic Marks:
									</Typography>
									<Typography
										variant="h4"
										align="center"
										color="primary"
									>{`${results.totalPercentage} / 40`}</Typography>
								</Box>
							</CardContent>
						</Card>
					</Box>
				)}
			</Box>
		</StyledContainer>
	);
};

export default FourExamsSet;
