import React, { useState } from "react";
import {
	Box,
	Typography,
	TextField,
	Grid,
	Button,
	Tooltip,
	Container,
	Divider,
	CardContent,
	Card,
} from "@mui/material";
import { styled } from "@mui/system";
import { marksIntialState } from "./utils";
import { School } from "@mui/icons-material";

const StyledSection = styled(Box)(({ theme }) => ({
	border: "1px solid rgba(0, 0, 0, 0.3)",
	padding: theme.spacing(3),
	borderRadius: "10px",
	marginBottom: theme.spacing(4),
	backgroundColor: "#f4f6f8",
	boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
}));

const StyledContainer = styled(Container)(({ theme }) => ({
	backgroundColor: "#e9f5ee",
	padding: theme.spacing(3),
	borderRadius: "15px",
	boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
	marginTop: theme.spacing(3),
}));

const TwoExamsSet = () => {
	const [matricMarks, setMatricMarks] = useState(marksIntialState);
	const [intermediateMarks, setIntermediateMarks] = useState(marksIntialState);
	const [results, setResults] = useState({
		matricPercentage: "",
		intermediatePercentage: "",
		totalPercentage: "",
	});
	const [showResults, setShowResults] = useState(false);

	const handleCalculate = () => {
		const matricPercentage =
			(parseFloat(matricMarks.obtained) / parseFloat(matricMarks.total)) *
				100 || 0;
		const intermediatePercentage =
			(parseFloat(intermediateMarks.obtained) /
				parseFloat(intermediateMarks.total)) *
				100 || 0;

		// Calculation formula
		const totalPercentage =
			(matricPercentage * 15) / 100 + (intermediatePercentage * 25) / 100;

		setResults({
			matricPercentage: matricPercentage.toFixed(2),
			intermediatePercentage: intermediatePercentage.toFixed(2),
			totalPercentage: totalPercentage.toFixed(2),
		});
		setShowResults(true);
	};

	const isCalculateDisabled = () => {
		const matricValid =
			matricMarks.obtained &&
			matricMarks.total &&
			!isNaN(parseFloat(matricMarks.obtained)) &&
			!isNaN(parseFloat(matricMarks.total));
		const intermediateValid =
			intermediateMarks.obtained &&
			intermediateMarks.total &&
			!isNaN(parseFloat(intermediateMarks.obtained)) &&
			!isNaN(parseFloat(intermediateMarks.total));

		return !(matricValid && intermediateValid);
	};

	return (
		<StyledContainer maxWidth="sm">
			<Box p={2}>
				<Typography
					variant="h4"
					gutterBottom
					sx={{
						fontSize: { xs: "1.5rem", sm: "2rem" },
						textAlign: "center",
						fontFamily: "'Poppins', Arial, sans-serif",
						fontWeight: 500,
						color: "#2f8831",
					}}
				>
					Two Exams Set
				</Typography>
				<Typography
					variant="h6"
					gutterBottom
					sx={{
						fontSize: { xs: "1rem", sm: "1.25rem" },
						textAlign: "center",
						fontFamily: "'Poppins', Arial, sans-serif",
						fontWeight: 400,
						color: "#555",
					}}
				>
					Enter Your Marks
				</Typography>

				<StyledSection>
					<Typography variant="h6" mb={2} color="primary">
						Matriculation Marks
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Total Marks"
								value={matricMarks.total}
								variant="outlined"
								onChange={(e) =>
									setMatricMarks({ ...matricMarks, total: e.target.value })
								}
								fullWidth
								type="number"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Obtained Marks"
								value={matricMarks.obtained}
								onChange={(e) =>
									setMatricMarks({ ...matricMarks, obtained: e.target.value })
								}
								fullWidth
								type="number"
							/>
						</Grid>
					</Grid>
				</StyledSection>

				<StyledSection>
					<Typography variant="h6" mb={2} color="primary">
						Intermediate Marks
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Total Marks"
								value={intermediateMarks.total}
								variant="outlined"
								onChange={(e) =>
									setIntermediateMarks({
										...intermediateMarks,
										total: e.target.value,
									})
								}
								fullWidth
								type="number"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Obtained Marks"
								value={intermediateMarks.obtained}
								onChange={(e) =>
									setIntermediateMarks({
										...intermediateMarks,
										obtained: e.target.value,
									})
								}
								fullWidth
								type="number"
							/>
						</Grid>
					</Grid>
				</StyledSection>

				<Tooltip title="Please Fill Academic Marks to Calculate">
					<Button
						variant="contained"
						color="primary"
						onClick={handleCalculate}
						disabled={isCalculateDisabled()}
						fullWidth
						sx={{
							mt: 2,
							padding: (theme) => theme.spacing(1.5),
							backgroundColor: "#2f8831",
							"&:hover": {
								backgroundColor: "#276e28",
							},
						}}
					>
						Calculate
					</Button>
				</Tooltip>

				{showResults && (
					<Box mt={4} display="flex" justifyContent="center">
						<Card
							variant="outlined"
							sx={{ maxWidth: 500, width: "100%", boxShadow: 4 }}
						>
							<CardContent>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="center"
									mb={2}
								>
									<School fontSize="large" color="primary" />
									<Typography
										variant="h5"
										align="center"
										ml={1}
										color="primary"
									>
										Calculated Results
									</Typography>
								</Box>

								<Divider />

								<Box mt={2} mb={2}>
									<Typography variant="body1" color="textSecondary">
										Matric Percentage:{" "}
										<strong>{results.matricPercentage}%</strong>
									</Typography>
									<Typography variant="body1" color="textSecondary">
										Intermediate Percentage:{" "}
										<strong>{results.intermediatePercentage}%</strong>
									</Typography>
								</Box>

								<Divider />

								<Box mt={3}>
									<Typography
										variant="h4"
										align="center"
										color="primary"
										fontWeight="bold"
									>
										Total Academic Marks:
									</Typography>
									<Typography
										variant="h4"
										align="center"
										color="primary"
									>{`${results.totalPercentage} / 40`}</Typography>
								</Box>
							</CardContent>
						</Card>
					</Box>
				)}
			</Box>
		</StyledContainer>
	);
};

export default TwoExamsSet;

// import React, { useState } from "react";
// import {
// 	Box,
// 	Typography,
// 	TextField,
// 	Grid,
// 	Button,
// 	Tooltip,
// 	Container,
// 	Divider,
// 	CardContent,
// 	Card,
// } from "@mui/material";
// import { styled } from "@mui/system";
// import { marksIntialState } from "./utils";
// import { School } from "@mui/icons-material";

// const StyledSection = styled(Box)(({ theme }) => ({
// 	border: "1px solid rgba(0, 0, 0, 0.5)",
// 	padding: "18px 10px",
// 	borderRadius: "5px",
// 	marginBottom: theme.spacing(4),
// 	backgroundColor: "rgba(255, 255, 255, 0.9)",
// 	boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
// }));

// const TwoExamsSet = () => {
// 	const [matricMarks, setMatricMarks] = useState(marksIntialState);
// 	const [intermediateMarks, setIntermediateMarks] = useState(marksIntialState);
// 	const [results, setResults] = useState({
// 		matricPercentage: "",
// 		intermediatePercentage: "",
// 		totalPercentage: "",
// 	});
// 	const [showResults, setShowResults] = useState(false);

// 	const handleCalculate = () => {
// 		const matricPercentage =
// 			(parseFloat(matricMarks.obtained) / parseFloat(matricMarks.total)) *
// 				100 || 0;
// 		const intermediatePercentage =
// 			(parseFloat(intermediateMarks.obtained) /
// 				parseFloat(intermediateMarks.total)) *
// 				100 || 0;

// 		// Calculation formula
// 		const totalPercentage =
// 			(matricPercentage * 15) / 100 + (intermediatePercentage * 25) / 100;

// 		setResults({
// 			matricPercentage: matricPercentage.toFixed(2),
// 			intermediatePercentage: intermediatePercentage.toFixed(2),
// 			totalPercentage: totalPercentage.toFixed(2),
// 		});
// 		setShowResults(true);
// 	};

// 	const isCalculateDisabled = () => {
// 		const matricValid =
// 			matricMarks.obtained &&
// 			matricMarks.total &&
// 			!isNaN(parseFloat(matricMarks.obtained)) &&
// 			!isNaN(parseFloat(matricMarks.total));
// 		const intermediateValid =
// 			intermediateMarks.obtained &&
// 			intermediateMarks.total &&
// 			!isNaN(parseFloat(intermediateMarks.obtained)) &&
// 			!isNaN(parseFloat(intermediateMarks.total));

// 		return !(matricValid && intermediateValid);
// 	};

// 	return (
// 		<Container maxWidth="sm">
// 			<Box p={2}>
// 				<Typography
// 					variant="h4"
// 					gutterBottom
// 					sx={{
// 						fontSize: { xs: "1.5rem", sm: "2rem" },
// 						textAlign: "center",
// 						fontFamily: "'Poppins', Arial, sans-serif",
// 						fontWeight: 400,
// 					}}
// 				>
// 					Two Exams Set
// 				</Typography>
// 				<Typography
// 					variant="h4"
// 					gutterBottom
// 					sx={{
// 						fontSize: {
// 							xs: "1rem",
// 							sm: "1.5rem",
// 							fontFamily: "'Poppins', Arial, sans-serif",
// 							fontWeight: 400,
// 						},
// 						textAlign: "center",
// 					}}
// 				>
// 					Enter Your Marks
// 				</Typography>

// 				<StyledSection>
// 					<Typography variant="h6" mb={2}>
// 						Matriculation Marks
// 					</Typography>
// 					<Grid container spacing={2}>
// 						<Grid item xs={12} sm={6}>
// 							<TextField
// 								label="Total Marks"
// 								value={matricMarks.total}
// 								variant="outlined"
// 								onChange={(e) =>
// 									setMatricMarks({ ...matricMarks, total: e.target.value })
// 								}
// 								fullWidth
// 								type="number"
// 							/>
// 						</Grid>
// 						<Grid item xs={12} sm={6}>
// 							<TextField
// 								label="Obtained Marks"
// 								value={matricMarks.obtained}
// 								onChange={(e) =>
// 									setMatricMarks({ ...matricMarks, obtained: e.target.value })
// 								}
// 								fullWidth
// 								type="number"
// 							/>
// 						</Grid>
// 					</Grid>
// 				</StyledSection>

// 				<StyledSection>
// 					<Typography variant="h6" mb={2}>
// 						Intermediate Marks
// 					</Typography>
// 					<Grid container spacing={2}>
// 						<Grid item xs={12} sm={6}>
// 							<TextField
// 								label="Total Marks"
// 								value={intermediateMarks.total}
// 								variant="outlined"
// 								onChange={(e) =>
// 									setIntermediateMarks({
// 										...intermediateMarks,
// 										total: e.target.value,
// 									})
// 								}
// 								fullWidth
// 								type="number"
// 							/>
// 						</Grid>
// 						<Grid item xs={12} sm={6}>
// 							<TextField
// 								label="Obtained Marks"
// 								value={intermediateMarks.obtained}
// 								onChange={(e) =>
// 									setIntermediateMarks({
// 										...intermediateMarks,
// 										obtained: e.target.value,
// 									})
// 								}
// 								fullWidth
// 								type="number"
// 							/>
// 						</Grid>
// 					</Grid>
// 				</StyledSection>

// 				<Tooltip title="Please Fill Academic Marks to Calculate">
// 					<Button
// 						variant="contained"
// 						color="primary"
// 						onClick={handleCalculate}
// 						disabled={isCalculateDisabled()}
// 						fullWidth
// 					>
// 						Calculate
// 					</Button>
// 				</Tooltip>

// 				{showResults && (
// 					<Box mt={4} display="flex" justifyContent="center">
// 						<Card
// 							variant="outlined"
// 							sx={{ maxWidth: 500, width: "100%", boxShadow: 3 }}
// 						>
// 							<CardContent>
// 								<Box
// 									display="flex"
// 									alignItems="center"
// 									justifyContent="center"
// 									mb={2}
// 								>
// 									<School fontSize="large" color="primary" />
// 									<Typography variant="h5" align="center" ml={1}>
// 										Calculated Results
// 									</Typography>
// 								</Box>

// 								<Divider />

// 								<Box mt={2} mb={2}>
// 									<Typography variant="body1">
// 										Matric Percentage:{" "}
// 										<strong>{results.matricPercentage}%</strong>
// 									</Typography>
// 									<Typography variant="body1">
// 										Intermediate Percentage:{" "}
// 										<strong>{results.intermediatePercentage}%</strong>
// 									</Typography>
// 								</Box>

// 								<Divider />

// 								<Box mt={3}>
// 									<Typography
// 										variant="h4"
// 										align="center"
// 										color="primary"
// 										fontWeight="bold"
// 									>
// 										Total Academic Marks:
// 									</Typography>
// 									<Typography
// 										variant="h4"
// 										align="center"
// 										color="primary"
// 									>{`${results.totalPercentage} /40`}</Typography>
// 								</Box>
// 							</CardContent>
// 						</Card>
// 					</Box>
// 				)}
// 			</Box>
// 		</Container>
// 	);
// };

// export default TwoExamsSet;
