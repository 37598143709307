import React from "react";
import { AppBar, Tabs, Tab, Typography, Box, Container } from "@mui/material";
import { styled } from "@mui/system";
import ThreeExamsSet from "./Exams/ThreeExamsSet";
import FourExamsSet from "./Exams/FourExamsSet";
import TwoExamsSet from "./Exams/TwoExamsSet";

const StyledContainer = styled(Container)(({ theme }) => ({
	padding: theme.spacing(3),
	borderRadius: "8px",
	border: `2px solid #2f8831`,
	boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
	marginTop: theme.spacing(2),
	backgroundColor: "#e9f5ee",
	maxWidth: "xl",
	width: "100%",
	disableGutters: true,
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
	marginBottom: theme.spacing(2),
	backgroundColor: "#2f8831",
	borderRadius: "8px 8px 0 0",
}));

const StyledTabPanel = styled(Box)(({ theme }) => ({
	borderRadius: "8px",
	border: `1px solid #ddd`,
	padding: theme.spacing(3),
	backgroundColor: "#fff",
	marginTop: theme.spacing(2),
	boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
	color: "#2f8831",
	fontFamily: "'Poppins', Arial, sans-serif",
	fontWeight: 600,
}));

// Tab Panel Component
const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
		>
			{value === index && <StyledTabPanel>{children}</StyledTabPanel>}
		</div>
	);
};

// Main App Component
const App = () => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const Tab2Label = (
		<>
			<StyledTypography
				variant="h6"
				align="center"
				style={{ marginBottom: "10px" }}
			>
				2 Exams Set
			</StyledTypography>
			<ol
				style={{
					margin: 0,
					paddingLeft: "20px",
					listStylePosition: "outside",
					textTransform: "none",
					fontWeight: "normal",
				}}
			>
				<li style={{ marginBottom: "5px" }}>Matriculation</li>
				<li style={{ marginBottom: "5px" }}>Intermediate</li>
			</ol>
		</>
	);

	const Tab3Label = (
		<>
			<StyledTypography variant="h6" align="center">
				3 Exams Set
			</StyledTypography>
			<ol
				style={{
					margin: 0,
					paddingLeft: "20px",
					listStylePosition: "outside",
					textTransform: "none",
					fontWeight: "normal",
				}}
			>
				<li style={{ marginBottom: "5px" }}>Matriculation</li>
				<li style={{ marginBottom: "5px" }}>Intermediate</li>
				<li style={{ marginBottom: "5px" }}>BA - BS - MBBS</li>
			</ol>
		</>
	);

	const Tab4Label = (
		<>
			<StyledTypography variant="h6" align="center">
				4 Exams Set
			</StyledTypography>
			<ol
				style={{
					margin: 0,
					paddingLeft: "20px",
					listStylePosition: "outside",
					textTransform: "none",
					fontWeight: "normal",
				}}
			>
				<li style={{ marginBottom: "5px" }}>Matriculation</li>
				<li style={{ marginBottom: "5px" }}>Intermediate</li>
				<li style={{ marginBottom: "5px" }}>BA - B.Sc</li>
				<li style={{ marginBottom: "5px" }}>MA - M.Sc</li>
			</ol>
		</>
	);

	return (
		<StyledContainer>
			<StyledTypography variant="h4" component="h1" align="center" gutterBottom>
				PPSC Academic Marks Calculator 2024
			</StyledTypography>
			<StyledTypography variant="h6" component="h6" align="center" gutterBottom>
				Calculate Your Academic Marks Accurately With Official PPSC Marks
				Formula 2024
			</StyledTypography>
			<StyledAppBar position="static">
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="inherit"
					variant="fullWidth"
					TabIndicatorProps={{
						style: { backgroundColor: "#fff", height: "3px" },
					}}
				>
					<Tab
						label={Tab2Label}
						sx={{
							"&.Mui-selected": {
								color: "#fff",
								fontWeight: "bold",
							},
						}}
					/>
					<Tab
						label={Tab3Label}
						sx={{
							"&.Mui-selected": {
								color: "#fff",
								fontWeight: "bold",
							},
						}}
					/>
					<Tab
						label={Tab4Label}
						sx={{
							"&.Mui-selected": {
								color: "#fff",
								fontWeight: "bold",
							},
						}}
					/>
				</Tabs>
			</StyledAppBar>
			<TabPanel value={value} index={0}>
				<TwoExamsSet />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<ThreeExamsSet />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<FourExamsSet />
			</TabPanel>
		</StyledContainer>
	);
};

export default App;
